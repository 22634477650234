
    
    @media screen and (max-width: 1024px) {
    
        .LayoutSpecific{
            max-width: 90%;
            margin: auto;
            display: flex;

            .logoPaitaServices{
                display: none;
            }

            .SpecificPage{
                margin: auto;
                width: 100%;
                display: flex;
                padding-top: 10%;
                align-items: center;
                justify-content: center;
                gap: 5rem;
                height: 100%;

                flex-direction: column;
                margin-top: 25%;


            .PhotosSpecificMobile{
                height: 100%;
                width: 100%;
                display: block;
                margin-bottom: 2%;
                margin-top: 2%;
                
        
                img{
                    height: 250px;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 20px;
    
                }
            }
            
            
                .BlocSpecific1{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .service-icons-desktop{
                        display: none;
                    }
            
                
                    h4{
                        margin-bottom: 0;
                    }
            
                    h2{
                        margin-top: 0;
                       text-transform:uppercase;
                       line-height: 2rem;
                       font-size: 2rem;
                       text-align: center;
                    }
            
                    .logoPaitaServices{
                        width:12rem;
                    }
            
                    p{
                        margin-top: 2%;
                        margin-bottom: 5%;
                        text-align: justify;
                        text-align-last:center ;
                        font-size: 0.85rem;
                        line-height: 1.1rem;
                        
                    }
                    .service-icons{
                        display: flex;
                        justify-content: center;
                        font-size: 2rem;
                        gap: 2%;
                        margin-top: 5%;
                    }
            
                    .HeaderSpecific{
            
            
            
            
            
                    }
            
            
            
            
                    .ContentSpecific{
                        display: flex;
                        justify-content:flex-end;
                        align-items: center;
            
            
            
                        .LogoSpecific{
                            display: flex;
                            flex-direction: row;
                            background-color: moccasin;
                            width: 30%;
                            gap: 1rem;
            
                            img{
                                width: 50px;
                                height: auto;
                            }
                        }
            
                        .ButtonSpecific {
                            width:70%;
            
                            button {
                                color: white;
            
                                width: 100%;
                                border: none;
                                height: 40px;
                                border-radius: 20px;
                                background: #0F2027;  /* fallback for old browsers */
            background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            
                            }
                        }
            
                    }
            
                    .ListeCles{
                        margin: auto;
                        padding: 0;
                        width: 100%;
            
                        ul{
            
                        }
                        li{
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #232526;  /* fallback for old browsers */
                            background: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
                            background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                            text-decoration: none;
                            list-style: none;
                            margin-bottom: 2%;
                            height: 35px;
                            font-weight: 700;
                            color: white;
                            border-radius: 20px;
                            font-size: 0.75rem;
                            letter-spacing: 0.1rem;
                            text-transform: uppercase;
            
                        }
                    }
            
            
                }
            
            
                .BlocSpecific2{
                    height: 550px;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    display: none;
            
            
                    .PhotosSpecific{
                        height: 100%;
                        width: 100%;
                        
                
                        img{
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            border-radius: 20px;
            
                        }
                    }
            
                }
            
            
            
            
            
            }
            
            }
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
    
    }