@media screen and (min-width: 601px) and (max-width: 1024px)  {

    .SpecificPage{
        margin: auto;
        width: 100%;
        display: flex;
        padding-top: 0%!important;
        align-items: center;
        justify-content: center;
        gap: 5rem;
        height: 100%;

        flex-direction: column;
        margin-top: 25%;


    .PhotosSpecificMobile{
        height: 100%;
        width: 100%;
        display: block;
        margin-bottom: 2%;
        margin-top: 2%;
        

        img{
            height: 250px;
            width: 100%;
            object-fit: cover;
            border-radius: 20px;

        }
    
    }}
}