// TABLETTE
@media screen and (min-width: 601px) and (max-width: 1024px) {

  .blocCardServices{
    .service-card {
      height: 300px;
      color: #ffffff;
    
    h4{
      font-size: 1.75rem;
    }
    
    strong {
      font-weight: 700;
    }
    
    h5{
      font-weight: 500;
      font-size: 1rem;
    }
    
    p{
      font-family: 'Manrope';
      line-height: 1.2rem;
      font-size: 0.85rem;
      margin-top: 2%;
      margin-bottom: 1%;
      padding: 1%;
      border-radius: 20px;
      background-color: #ffffff26;
      min-height: 85px;
      min-width: 100%;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    
    }
      
      .service-image {
        width: 25%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-top-left-radius: 18px;
          border-bottom-left-radius: 18px;
        }
      }
    
      .service-content {
        width: 75%;
        padding: 3%;
        padding-top: 5%;
        padding-bottom: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    
    
        .services-tags {
          width: 100%;
          gap: 10px;
          padding: 0;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
    
    
          li {
            display: flex;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.256);
            font-family: 'Montserrat';
            font-weight: 700;
            gap: 5px;
            list-style: none;
            padding: 0;
            margin: 0;
            border-radius: 10px;
            padding-left: 5%;
            padding: 1.5%;
            font-size: 0.6rem;
            color: rgb(255, 255, 255);
            filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.19));
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    
    
          }
        }
    
        .service-icons {
          justify-content: flex-end;
          display: flex;
          font-size: 1.25rem;
          filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.19));
    
        }
      }
    }}
    
}

