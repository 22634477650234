// src/components/Contact/ContactForm/ContactForm.scss
.contactFormContainer {
    border-radius: 18px;
    width: 80%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
    background: #000000;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #434343aa, #26262692);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #434343c4, #262626b3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }
  
  .contactForm {
    display: flex;
    align-items: center;
    width: 95%;
    margin: auto;
    flex-direction: column;
    padding-top: 5%;
    padding-bottom: 5%;
    backdrop-filter: blur(8px);

    
  }



  .blocBoutonContact{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    .boutonsBloc{
      width: 95%;
      height: 40px;
      padding: 1%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-color: red!important;

      .buttonInfo{
        text-decoration: none;
        color: white;
        background-color: rgb(0, 0, 0);
        width: 100%;
        padding: 1%;
        border-radius: 10px;

      }

    }


    .textInfoForm{
      background-color: rgba(255, 255, 255, 0.24);
      width: 95%;
      padding: 1%;
      border-radius: 10px;
      margin-top: 2%;
      text-align: left;
      backdrop-filter: blur(10ox);
      color: white;


  


    }



   

    button{
      width: 95%;
      border-radius: 10px;
      border: none;
      margin-top: 2%;
      height: 40px;
    }
  }
  
  .formUpperRow {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
    

          
    h3 {
      font-weight: 900;
      text-align: left;
      margin: 0;
      font-size: 4rem;
      color: white;
      margin-right: 0;
      line-height: 4rem;
      
    }

  
    .formTitle {
        align-items: center;
        display: flex;
      flex-basis: 50%; // Adjust the width of the title column as necessary
      padding-right: 1rem;
      

      
      

    }
  
    .formFields {
      flex-basis: 100%; // Adjust the width of the form fields column as necessary
      display: flex;
      width: 100%;
      flex-direction: column;

      font-family: 'Montserrat';
      

    }
  }
  
  .formGroup {
    margin-bottom: 0.5rem;

    width: 100%;
    
    
  }

  .formGroup textarea, .formFields{
    
  }
  
  .formGroup input,
  .formGroup textarea {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 2rem;
    padding: 1%;
    font-family: 'Manrope';
    font-size: 1rem;


    
    
    &:focus {
      outline: none;
      border-color: #007bff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      
      
    }
  }
  
  .messageGroup {
    textarea {
      height: auto; // Adjust as needed
      min-height: 8rem;
      resize: vertical;
    }
  }
  
  .submitBtn {
    align-self: flex-end;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 18px;
    font-family: 'Montserrat';
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 0.5rem; // Adds some space above the button
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
  

  }

  @import './ContactFormMobile.scss';
  @import './ContactFormTablette.scss';

