@media screen and (min-width: 601px) and (max-width: 1024px) {

    .portfolioSection {
        background: url("../../../Assets/portfolio.webp");
        background-position: right;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        margin: auto;
        margin-top: 5%;
        padding-top: 5%;
      
        .button-details {
          margin-bottom: 4%;
        }
      
        .portfolioIcon {
          width: 100px;
        }
      
        .portfolioBlock {
          width: 90%;
          margin: auto;
          margin-bottom: 5%;
          display: flex;
          justify-content: center; /* Center the carousel horizontally */
        }
      
        .portfolio-carousel-wrapper {
          width: 100%;
          margin-bottom: 2%;

        }
      }
}

@media screen and (max-device-width: 601px) and (orientation: portrait) {

  .portfolioSection {
      background: url("../../../Assets/portfolio.webp");
      background-position: right;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      margin: auto;
      margin-top: 5%;
      padding-top: 5%;
    
      .button-details {
        margin-bottom: 4%;
      }
    
      .portfolioIcon {
        width: 100px;
      }
    
      .portfolioBlock {
        width: 90%;
        margin: auto;
        margin-bottom: 5%;
        display: flex;
        justify-content: center; /* Center the carousel horizontally */
      }
    
      .portfolio-carousel-wrapper {
        width: 100%;
        margin-bottom: 10%;

      }
    }
}