footer {
    background-color: transparent;
    padding: 1rem;
    display: flex;
    justify-content: center;
    height: 200px;
    padding-bottom: 5%;
  }
  
  .footer-container {
    background-color: #2A2A2A; // Couleur de fond pour la forme de la pilule
    border-radius: 10rem; // Arrondir les coins pour la forme de pilule
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8rem 0rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); // Ajoute une ombre pour le relief
    gap: 1rem;
    width: 90%;
  }

  .footer-social{
    width: 40%;
    display: flex;
    justify-content: space-around;
    margin-top: 0;

  }



.footer-social {

      a {
        color: white;
        text-decoration: none;
        box-shadow: (0px 10px 20px rgba(0, 0, 0, 0.29));


        &:hover {
          color: #ddd;
          scale: 1.1;
        }

        svg {
          transition: color 0.3s;
        }
      }}

  
  .footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 30%;

    p{
      margin-top: 0;
      color: whitesmoke;
    }

    .footer-logo-image{
        width: 15rem;
        height: auto;
        filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.89));

        

    }
  }

  
  @media (max-width: 600px) {
    footer {
      padding: 1rem;
      display: flex;
      justify-content: center;
      margin-bottom: 5%;
    }
    
    .footer-container {
      background-color: #2A2A2A; // Couleur de fond pour la forme de la pilule
      border-radius: 50px; // Arrondir les coins pour la forme de pilule
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0rem 0rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); // Ajoute une ombre pour le relief
      gap: 1rem;
      width: 100%;
      margin-bottom: 2%!important;
    }
  
    .footer-social{
      width: 80%;
      display: flex;
      justify-content: space-around;
      margin-top: 0;
  
    }
  
  
  
  .footer-social {
  
        a {
          color: white;
          text-decoration: none;
          box-shadow: (0px 10px 20px rgba(0, 0, 0, 0.29));
  
  
          &:hover {
            color: #ddd;
            scale: 1.1;
          }
  
          svg {
            transition: color 0.3s;
          }
        }}
  
    
    .footer-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
  
      p{
        margin-top: 0;
        text-align: center;
      }
  
      .footer-logo-image{
          width: 10rem;
          height: auto;
          filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.89));
  
          
  
      }
    }
  
  }
  

  @media (min-width: 601px) and (max-width: 1064px) and (orientation:portrait) {
    footer {
      background-color: transparent;
      padding: 1rem;
      display: flex;
      justify-content: center;
      padding-bottom: 5%;
    }

    .footer-container {
      background-color: #2A2A2A; // Couleur de fond pour la forme de la pilule
      border-radius: 50px; // Arrondir les coins pour la forme de pilule
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0rem 0rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); // Ajoute une ombre pour le relief
      gap: 1rem;
      width: 100%;
      margin-bottom: 2%!important;
    }
  }