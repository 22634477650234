@media screen and (max-width: 600px) {

    .detailed-parcours{
        margin: auto;
        padding-top: 19%;
    
        .headerParcoursDetailed{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 50px;
        }
        
        h2{
            line-height: 2rem;
            order: 2;
            margin-top: 0;
        }
    
        .logo-parcours{
            width:175px;
            height: 100%;
        }
    
    
    }
}