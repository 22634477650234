@import './GlobalStyles//variables.scss';
@import './GlobalStyles/base.scss';


// DESKTOP


.App {
  text-align: center;
  max-width: 1920px;
  margin: auto;

  footer {
    margin-bottom: 5%!important;
  }

  .iconeMobile {
    display: none;
  }

}

@font-face {
  font-family: 'Manrope';
  src: url('../public/fonts/Manrope-VariableFont_wght.ttf')format('truetype-variations');
  font-weight: 100 800;
  /* Définit la plage de poids disponible */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {}


p {}

strong {
  font-weight: 500;
}

// STYLE DU BOUTON + D'INFOS
.button-details {
  position: relative;
  background: #232526;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #414345, #232526);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #414345, #232526);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 4em;
  color: white;
  padding: 0.8em 1.8em;
  cursor: pointer;
  user-select: none;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.4s;
  border: none;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  box-shadow: rgba(0, 0, 0, 0.441) 0px 5px 15px;
  backdrop-filter: blur(6px);
  font-size: 1rem;
}



.button-details:hover {
  transition-duration: 0.1s;
  background-color: #3A3A3A;
  scale: 1.02;
}


$clr-primary: #f7cfc8;
$clr-secondary: #4d84c8;
$clr-wht: #fffcf6;


h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  color: rgb(96, 96, 96);
}

p {
  font-family: 'Montserrat', sans-serif;

}

.blocInfoSection {
  line-height: 2rem;
  padding: 1.5%;
  border-radius: 30px;
  backdrop-filter: blur(6px);
  margin-bottom: 2%;
  width: 100%;
  text-align: justify;

}

h2 {

  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.2rem;
  text-shadow: .1em .1em 0 hsla(0, 0%, 72%, 0.449);
  font-weight: 800;
}

h3 {
  color: #054aea;
  margin-top: 10%;

}



// STYLE DU HEADER DE CHAQUE SECTION
.sectionHeader {
  margin: auto;
  margin-top: 2%;
  margin-bottom: 0;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 2%;

  .iconeSectionHeader {
    width: auto;
    height: 7.5rem;
  }

  // Illustration de la section services

  // Titre ex. "SERVICES"
  h2 {
    margin-top: 1%;
    margin-bottom: 1%;
    line-height: 4.5rem;
  }

  h3 {
    color: rgb(94, 94, 94);
    margin-top: 0;
    margin-bottom: 2%;
  }

  p {
    text-align: justify;
    text-align-last: center;
    width: 80%;
    font-size: 1rem;
    margin-bottom: 2%;
    box-shadow: rgba(149, 157, 165, 0.1) 0px 3px 14px;
  }

  img {
    width: 80px;
  }


}




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}




// TABLETTE
@media screen and (min-device-width: 601px) and (max-device-width: 1024px) and (orientation: portrait) {


  
  .App {
    text-align: center;

    .iconeMobile {
      display: block;
      height: 100%;
      width: 40px;
    }

  }

  .sectionHeader {
    margin: auto;
    margin-top: 2%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // Illustration de la section services

    // Titre ex. "SERVICES"
    h2 {
      width: 75%;
      line-height: 2rem;
      margin-bottom: 3%;
    }


    h3 {
      color: rgb(94, 94, 94);

    }

    p {
      margin-top: 0;
      width: 90%;
      padding: 2%;
      font-size: 0.9rem;
    }

    img {
      width: 80px;
    }


  }

  .blocInfoSection {
    line-height: 1.5rem;
    margin-top: 2%;
    margin-bottom: 1%;
    padding: 1.5%;
    border-radius: 30px;
    backdrop-filter: blur(6px);
    margin-bottom: 2%;
  }


  .sectionHeader {
    margin: auto;
    margin-top: 2%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // Illustration de la section services

    // Titre ex. "SERVICES"
    h2 {
      margin-top: 0;
      margin-bottom: 0;
      width: 50%;
      line-height: 4rem;
      font-size: 4rem;
    }

    h3 {
      color: rgb(94, 94, 94);
      margin-top: 0;
      font-size: 1.55rem;
    }

    p {
      margin-top: 0;
    }

    img {
      width: 80px;
    }


  }

  .blocInfoSection {
    line-height: 1.5rem;
    margin-top: 2%;
    margin-bottom: 1%;
    padding: 1.5%;
    border-radius: 30px;
    backdrop-filter: blur(6px);
    margin-bottom: 2%;
  }



}


// MOBILE
@media screen and (max-device-width: 601px) and (orientation: portrait) {
  .sectionHeader {
    margin: auto;
    margin-top: 2%;
    margin-bottom: 0;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 2%;
  
    .iconeSectionHeader {
      width: auto;
      height: 5rem;
    }
  
    // Illustration de la section services
  
    // Titre ex. "SERVICES"
    h2 {
      margin-top: 1%;
      margin-bottom: 1%;
      line-height: 2.5rem;
      font-size: 2.5rem;
      width: 80%;

    }
  
    h3 {
      color: rgb(94, 94, 94);
      margin-top: 0;
      margin-bottom: 2%;
      font-size: 1.5rem;
    }
  
    p {
      text-align: justify;
      text-align-last: center;
      width: 95%;
      font-size: 0.9rem;
      line-height: 1.5rem;
      margin-bottom: 2%;
      box-shadow: rgba(149, 157, 165, 0.1) 0px 3px 14px;
    }
  
    img {
    }
  
  
  }
  
}