.contactSection {
    background: url("../../../Assets/shaking.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: auto;
    margin-bottom: 5%;
    margin-top: 5%;
    padding-bottom: 5%;
    padding-top: 5%;

    .ContactHeader {
        width: 70%;
        margin: auto;

        h2 {
            margin-top: -3%;
            margin-bottom: 0;
        }

        h3 {
            margin-top: 0;
            margin-bottom: 0;
        }

        .contactIcon {
            width: 15%;
            margin-bottom: 1%;
        }
    }
}

.contactBlock {
    margin: auto;
    width: 85%;
}

.buttonContactSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 90%!important;
    height: 8rem;
    .contactButton{
        width: 90%;
        gap: 10%;
        height: 3rem;

    }

}

.buttonContactSection {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    margin-top: 2%;
    width: 100%;

    .contactButton {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        width: 40%;
        border: none;
        border-radius: 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background: #232526;
        background: -webkit-linear-gradient(to right, #414345, #232526);
        background: linear-gradient(to right, #414345, #232526);
        color: white;
        gap: 15px;
        font-size: 1.2rem;
        position: relative; /* Pour positionner correctement l'info de contact */
        overflow: hidden; /* Masquer l'overflow pour les transitions */
        text-decoration: none; /* Enlever la décoration de lien */
        padding: 0 20px; /* Ajouter un padding pour l'espace intérieur */

        .hoverContent {
            display: none; /* Masquer par défaut */
        }

        &:hover .defaultContent {
            display: none; /* Masquer le contenu par défaut au survol */
        }

        &:hover .hoverContent {
            display: inline; /* Afficher le contenu au survol */
        }
    }
}

@import './ContactMobile.scss';
@import './ContactTablette.scss';
