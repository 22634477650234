// MOBILE
@media screen and (max-width: 600px) {
    .contactSection{
        background: url("../../../Assets/shaking.webp"); /* Chemin relatif depuis le dossier public */
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin: auto;
        margin-bottom: 5%;
        margin-top: 5%;
        padding-bottom: 5%;
        padding-top: 5%;
        .ContactHeader{
            width: 70%;
            margin: auto;
    
         h2{
            margin-top: -3%;
            margin-bottom: 0;
         }
    
         h3{
            margin-top: 0;
            margin-bottom: 0;
         }
    
            .contactIcon{
                width: 15%;
                margin-bottom: 1%;
            }
        }
    }
    
    .buttonContactSection{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2%;
        margin-bottom: 2%;
        width: 100%;
        height: 8rem;
        .contactButton{
            width: 90%;
            gap: 10%;
            height: 3rem;
            margin-bottom: 2%;
    
        }
    }


    
    
    .contactBlock {
        margin: auto;
        width: 85%;
        margin-top: 3%;
    }
    
    
    

}