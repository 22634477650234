// MOBILE
@media screen and (max-width: 601px) {
    // Composant carrousel Parcours
    .parcoursCarrousel {
        position: relative;
        width: 100%;
        margin-bottom: 5%;
        min-height: 60vh;
    
        .colonne1 {
            display: flex;
            width: 100%!important;
    
        }
    
    
        // Card Stap du carrousel
        .carouselCard {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 80%;
            height: auto;
            background: linear-gradient(to right, #434343, #161616c0);
            backdrop-filter: blur(6px);
            color: #fff;
            padding: 20px;
            border-radius: 20px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transition: transform 0.5s ease, width 0.5s ease;
            z-index: 1;
    
            .contenuCard {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
    
            }
    
            .tagLigne {}
    
            // Numéro + Titre + Texte (Colonne 1)
            .colonne1 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: 75%;
                margin-bottom: 1%;
                order: 2;
    
                .ligneUn {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;
    
                    .stepNumber {
                        font-size: 1.5rem;
                        color: rgb(255, 255, 255);
                        text-align: center;
                        margin-bottom: 0;
                    }
    
                    .stepTitle {
                        font-size: 1.5rem;
                        color: rgb(255, 255, 255);
                        margin-top: 0;
                    }
                }
    
    
                .stepDescription {
                    font-size: 0.9rem;
                    line-height: 1.1rem;
                    width: 100%;
                    text-align: justify;
                    text-align-last: left;
                    font-family: 'Manrope';
                    margin-bottom: 1rem;
    
                }
    
    
            }
    
            // Etapes clés liste
            .stepKeyPoints {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                margin-top: 0;
                gap: 10px;
    
                // Etape clé
                .keyStep {
                    align-items: center;
                    justify-content: center;
                    background-color: rgb(255, 255, 255);
                    color: #000;
                    width: 100%;
                    margin-bottom: 0px;
                    border-radius: 10px;
                    font-family: 'Manrope';
                    font-weight: 400;
                    font-size: 0.85rem;
                    text-align: left;
                    line-height: 0.95rem;
                    padding: 1%;
                    min-height: 30px;
                }
            }
    
            // Illustration de l'étape
            .stepLogo {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15%;
                margin: auto;
                margin-bottom: 2%;
                order: 1;
    
                img {
                    width: 100%;
                    height: 80%;
                }
            }
    
            &.active {
                z-index: 5;
            }
    
            @for $i from 0 through 4 {
                &:nth-child(#{$i + 1}) {
                    z-index: 5 - $i;
                    transform: translateX(-50%) scale(1 - $i * 0.05) translateY(-$i * 5%);
                }
            }
    
            .stepNumber {
                font-weight: 100;
            }
    
        }
    
    
    }
    }