// TABLETTE
@media screen and (min-width: 601px) and (max-width: 1024px) {

  // Conteneur de la section services
  section.servicesSection {
    max-width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 100%;
    margin: auto;
    height: auto;
    padding-top: 150px;

    .desktop{
      display: block;
    }

    .mobile{
      display: none;
    }

    // Header de la section services


    // Conteneur des Cards de services
    .flexContainer {
      width: 100%;
      margin: auto;

      .serviceBlock {
        margin: auto;
      }
    }
  }

}
