// TABLETTE
@media screen and (min-width: 601px) and (max-width: 1024px)  {
  .homePage {
    background: url("../../../Assets/mainstablette.webp");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    height: 97vh;
    background-position-y: 30px;

    // Bloc RS
    .socialHome{
      left: 0;
      position: absolute;
      bottom: 6%;
      gap: 2%;
      img{
        width: 35px;
      }
    }
    // Mains animés Desktop
    .fond-mains{
      display: none;
    }

    // Bloc texte animé
    .texteHome {
      top: 57%;
      background: linear-gradient(to right, #414345, #232526);
      border-radius: 25px;
      padding: 0.5rem;
      width: 80%;
      margin-top: 2%;
    }

    // Bloc texte LYON-GAP
    .textLoca {
      background: linear-gradient(to right, #414345, #232526);
      border-radius: 30px 30px 0 0;
      height: 4%;
      width: 70%;
    }
    // Bloc logo central
    .logo {
      width: 50%;

      .logoCentral {
        width: 100%;
      }
    }
  }
}