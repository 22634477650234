@media screen and (min-width: 1500px){
    .parcoursSection{
        .parcoursHeader{
            .ampoule{
                width: 8%;
            }

            h2{
            }

            p{
                width: 60%;
                text-align-last: center;
            }
        }
    }
}

// TABLETTE
@media screen and (min-width: 601px) {
    .parcoursSection{
        background-position: top left!important;
        background-size: 40%;
        background-position-y: 90px;
        background-position-x: 109%;
        background-repeat: no-repeat;
        padding-bottom: 50px;
        padding-top: 50px;
        height: auto;



        .parcoursHeader{

            .ampoule{
                width: 20%;
                margin-bottom: 0;
                margin-top: 0;
            }
            h2{
                margin-bottom: 0;
            }

            h3{
                width: 85%;
                margin-top: 0;
                margin: auto;
            }

            h4{
            }

            p{
                width: 85%;
                margin: auto;
                text-align: justify;
                margin-top: 2%;
                font-weight: 500;
                text-align-last: center;

            }

    
        }
 
        
    }

    

    .ParcoursBlock{
        height: auto;
        margin-top: 5%;
    }

    .details-button{
        button{
            margin-top: 2%;
        }
    }
}


@media screen and (max-width: 600px) {

    .parcoursSection{
        background-position: top left!important;
        background-size: 40%;
        background-position-y: 2rem!important;
        background-position-x: 109%;
        background-repeat: no-repeat;
        padding-bottom: 50px;
        padding-top: 50px;
        margin-top: 10%;
        height: auto;
        .parcoursHeader{
            .ampoule{
                width: 8%;
            }

            h2{
            }

            p{
                width: 60%;
                text-align-last: center;
            }
        }
    }

    .ParcoursBlock{
        height: auto;
        padding-top: 10%;
        padding-bottom: 10%;
    }


}