.portfolioSection {
        background: url("../../../Assets/portfolio.webp");
        background-position: right;

        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        margin: auto;
        margin-top: 5%;
        padding-top: 5%;
      
        .button-details {
          margin-bottom: 4%;
        }
      
        .portfolioIcon {
          width: 100px;
        }
      
        .portfolioBlock {
          width: 90%;
          margin: auto;
          display: flex;
          justify-content: center; /* Center the carousel horizontally */
        }
      
        .portfolio-carousel-wrapper {
          width: 75%;

        }
      }

      @import './A-PortfolioResponsive.scss';