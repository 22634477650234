@media screen and (max-width: 601px){
    .philo-section{
        .PhiloHeader{
            h2{
                margin-top: -3%;
                margin-bottom: 0;
            }
    
            h3{
                margin-top: 0;
            }
    
            p {
                margin-top: 0;
            }
    
            .GifPhilo{
            }
    
        }
    }

}


// MOBILE
@media screen and (max-width: 601px) {
    // Section Philosophie
.philo-section {
    background: url("../../..//Assets/parcours.webp") no-repeat left / contain;
    margin: auto;
    width: 100%;
    margin-bottom: 5%;
    margin-top: 5%;
    padding-bottom: 5%;
    padding-top: 5%;



    // Grille de cartes
    .philo-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 5rem;
        height: 750px;
        width: 90%;
        margin: auto;
        margin-top: 50px;
    }

    // Carte philosophie
    .philo-card {
        cursor: pointer;
        transition: transform 0.4s ease-out;
        transform-style: preserve-3d;
        

        &:hover {
            transform: scale(1.15);
            z-index: 100000;

        }

        &.flipped {
            .card-front { transform: rotateY(180deg); }
            .card-back { transform: rotateY(0deg); }
            z-index: 100000;
        }

        .card-front, .card-back {
            position: absolute;
            top: 0;
            left: 0;
            width: 90%;
            height: 90px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 15px;
            backface-visibility: hidden;
            transition: transform 0.6s linear;
            border-radius: 20px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            color: white;
            background: linear-gradient(to right, #434343d3, #000000dc);
            backdrop-filter: blur(10px);
        }

        .card-front {
            h5 {
                font-size: 2rem;
                color: white;
            }
        }

        .card-back {
            background-color: #11285c;
            transform: rotateY(-180deg);

            h6, p {
                margin: 0;
                color: white !important;
            }

            p { margin-top: 0.2rem; 
            font-size: 0.75rem;}

            .card-icon {
                width: 30px;
                height: 30px;
            }
        }
    }

    // Titre du verso de la carte
    .card-back-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    // Illustration de la carte
    .card-icon {
        width: 50px;
        height: 50px;
    }
}
}

