.even-style, .odd-style {
    // Votre style pour les IDs pairs
  max-width: 100%;
  margin: auto;


      
      .header {
        justify-content: flex-start;
        align-items: flex-end;
        gap: 2rem;
        margin: auto;
    
        .step-number {
          font-weight: bold;
          color: #000;
          margin-top: 0;
        }
    
        .step-title {
          font-weight: bold;
          text-transform: uppercase;
          color: #000;
          margin-top: 0px;
          font-size: 3.3rem;

        }
    
        .step-logo {
          width: 100px;
          // Styles pour le logo, si vous avez une classe spécifique pour cela
        }
      }
    
  
    
   
    
  
    
      .step-illustration {
        // Styles pour l'illustration, si vous avez une classe spécifique pour cela
        align-self: flex-end;
      }

      .header{
        margin-top: 0;
        margin-top: 5%;
        margin-bottom: 10%;
      }
  
      .bloc1{
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-top: 0;
          width: 100%;
          margin-bottom: 2%;
          height: 250px;
          .col1{
            order: 1;
            height: 100%;
            margin-top: 0;
            margin-bottom: 0;
              margin-right: 2%;
              width: 60%;
              
              .summary {
                  margin-top: 10px;
                  width: 100%;
                  text-align: justify;
                  margin-bottom: 0;
                }
  
                .ligne1{
                  margin-top: 0;
                  display: flex;
                  flex-direction: row;
                  align-items: flex-end;
                  justify-content: flex-start;
                  gap: 2%;

                  .step-number{
                    margin-top: 0;
                  }
                }
          }
  
          .col2{
            width: 25%;
            order: 3;
            height: 80%;
            display: flex;
        
            .tags {
                display: flex;
                flex-direction: column;
                justify-content: space-between; // Distribue l'espace verticalement
                list-style: none;
                padding-left: 0;
                width: 100%;
                height: 100%; // Hauteur totale du conteneur .tags
                margin-top:0;
                margin-bottom: 0;
                li {
                    background-color: #eee;
                    border-radius: 25px;
                    font-family: 'Montserrat';
                    font-weight: 600;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    flex: 1; // Les éléments li occupent l'espace de manière égale
                    margin: 10px 0; // Ajoutez une marge pour séparer les éléments li
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        
  
          .col3{
            width: 15%;
              margin-top: 0;
              display: flex;
              align-items: flex-end;
              justify-content: center;
              margin-right: 3%;
              height: 100%;  
              img{
                width: 100%;
              }
          }
      }
  
      .bloc2{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 1%;
          gap: 5%;
  
    
  
          .image-container {
            order: 2;
              width: 100%;
              gap: 2%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              
          
              .image-block {
                width: 30%; // Ajustez la largeur selon votre mise en page
                height: 200px; // Hauteur des blocs d'image
                background-color: #c4c4c4; // Couleur de fond des placeholders d'image
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px; // Bordures arrondies pour les images
                // Ajoutez des styles de background si vous utilisez des images
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                background-size: cover;
                background-repeat: no-repeat;
  
  
  
                img{
                  width: 100%;
                  height: 100%;
  
                }
              }
            }
      }
    }

    .odd-style{

      .col1{
        order: 1;


          .summary{

          }

          .ligne1{
            justify-content: flex-start!important;
          }
        
      }

      .col2{
        order: 2;
        margin-right: 5%;
      }

      .col3{
        order: 3;
      }
    }

    
