// TABLETTE
@media screen and (min-width: 601px) and (max-width: 1024px)  {

    .contactFormContainer {
      border-radius: 18px;
      width: 100%;}
      .formUpperRow {
      h3{
        font-size: 3rem;
        line-height: 3rem;
      }}
  }
    