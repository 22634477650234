// TABLETTE
@media screen and (min-width: 601px) and (max-width: 1024px) {
    // Composant carrousel Parcours
.parcoursCarrousel {


    .colonne1 {
    }


    // Card Stap du carrousel
    .carouselCard {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 260px;
        backdrop-filter: blur(6px);
        color: #fff;
        padding: 20px;
        border-radius: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: transform 0.5s ease, width 0.5s ease;
        z-index: 1;

        .contenuCard {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

        }

        .tagLigne {}

        // Numéro + Titre + Texte (Colonne 1)
        .colonne1 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 75%;
            margin-bottom: 1%;

            .ligneUn {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                .stepNumber {
                    font-size: 2rem;
                    color: rgb(255, 255, 255);
                }

                .stepTitle {
                    font-size: 1.6rem;
                    color: rgb(255, 255, 255);
                    text-align: left;
                }
            }


            .stepDescription {
                font-size: 0.80rem;
                line-height: 1.1rem;
                width: 100%;
                text-align: justify;
                text-align-last: left;
                font-family: 'Manrope';
                margin-bottom: 0;

            }


        }

        // Etapes clés liste
        .stepKeyPoints {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            margin-top: 0;
            grid-gap: 1rem;

            // Etape clé
            .keyStep {
                background-color: rgb(255, 255, 255);
                color: #000;
                width: 100%;
                margin-bottom: 10px;
                border-radius: 10px;
                font-family: 'Manrope';
                font-weight: 400;
                font-size: 0.70rem;
                text-align: left;
                line-height: 0.95rem;
                padding: 1%;
                min-height: auto;
            }
        }

        // Illustration de l'étape
        .stepLogo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15%;

            img {
                width: 100%;
                height: 80%;
            }
        }

        &.active {
            z-index: 5;
        }

        @for $i from 0 through 4 {
            &:nth-child(#{$i + 1}) {
                z-index: 5 - $i;
                transform: translateX(-50%) scale(1 - $i * 0.05) translateY(-$i * 5%);
            }
        }

        .stepNumber {
            font-weight: 100;
        }

    }


}
}

