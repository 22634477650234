#blocProjetSolo {
  min-height: 100vh;
  display: flex;
}

.project-detail-page {
  display: flex;
  flex-direction: column;
  justify-content: center!important;
  width: 90%;
  margin: auto;
  padding-top: 2%;

  .logoProjetPortfolio {
    width: 8rem;
  }

  h2 {
    font-size: 3.5rem;
    text-transform: uppercase;
    margin-top: 1%;
  }

  .project-detail-page-ligne1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 5%;
    height: 200px;

    .project-detail-page-col1 {
      display: flex;
      flex-direction: column;
      width: 70%;

      .project-languages {
        color: rgb(0, 0, 0);
        justify-content: flex-start;
        margin-bottom: 2%;
        margin-top: 0;
      }

      .projectUrl {
        background-color: rgb(0, 0, 0);
        width: 88%;
        letter-spacing: 5px;
        padding: 0.5%;
        text-decoration: none;
        color: white;
        background: #232526; /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #414345, #232526); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        border-radius: 12px;
        padding: 8px;
        padding-left: 3%;
        margin-bottom: 2%;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }

      p {
        margin-bottom: 0;
        text-align: justify;
        width: 90%;
        line-height: 1.5rem;
        font-size: 1rem;
      }
    }

    .project-detail-page-col2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 30%;
      height: 100%;

      ul {
        padding-left: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
        margin: 0;

        li {
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          font-family: 'Montserrat';
          color: white;
          background: #414345;
          padding: 10px;
          border-radius: 15px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
      }
    }
  }

  .project-detail-page-ligne2 {
    width: 100%;
    margin: auto;

    .masonry-gallery {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .masonry-item {
      flex: 1 1 calc(25% - 10px);
      box-sizing: border-box;
    }

    .masonry-item img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 15px;
      object-fit: cover;
    }
  }

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 90%;
    height: 90%;
    overflow: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 180000!important;
    align-items: center;
    justify-content: center;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }

  .modal img {
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: auto;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #000;
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  height: auto;
  max-height: 90vh;
  overflow: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1001;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.navigation-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #333;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1002;
}

.navigation-button.prev {
  left: 10px;
}

.navigation-button.next {
  right: 10px;
}

@media screen and (max-width: 768px) {
  .modal {
    width: 90%;
    height: auto;
    max-height: 80vh;
    padding: 15px;
  }
}

@import './C-ProjectDetailPageMobile.scss'