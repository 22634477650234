@import "/src/GlobalStyles/variables.scss"; // Assurez-vous que le chemin d'accès est correct.

// MOBILE
@media screen and (max-width: 1024px) {
  .project-detail-page {
    width: 95%;
    margin: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20%;

    .logoProjetPortfolio {
      width: 5rem;
      margin: auto;
      display: block;
      margin-bottom: 1rem;
    }

    h2 {
      font-size: 2rem;
      text-align: center;
      margin-bottom: 1rem;
    }

    .project-detail-page-ligne1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5%;
      height: auto;

      .project-detail-page-col1 {
        width: 100%;
        text-align: center;
        margin-bottom: 2rem;

        .projectUrl {
          display: block;
          width: 80%;
          margin: auto;
          padding: 0.5rem;
          font-size: 0.85rem;
          border-radius: 8px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          background: #414345; /* Fallback color */
          color: white;
          text-decoration: none;
        }

        p {
          width: 90%;
          margin: 1rem auto;
          text-align: justify;
        }
      }

      .project-detail-page-col2 {
        width: 100%;
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding: 0;
          list-style: none;

          li {
            margin: 0.5rem;
            padding: 0.5rem 1rem;
            font-size: 0.9rem;
            border-radius: 8px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            background: #414345;
            color: white;
          }
        }
      }
    }

    .project-detail-page-ligne2 {
      width: 100%;
      margin: auto;
      .slick-slide {
        box-shadow: none;
        background-color: transparent;
        display: flex;
        justify-content: center;
        margin-bottom: 25px!important;
      }
    
      .slick-dots li button:before {
        color: #000;
      }
    
      .slick-prev, .slick-next {
        display: none !important;
      }
      
      .slick-list {
        overflow: hidden;
  
      }
      
      .slick-track {
        display: flex;
        justify-content: center;
  
      }
  
      .slick-slide {
        margin: 0 15px;
    }

      img {
        height: 15rem;
        object-fit: cover;
        width: 100%;
      }
    }

    .modal {
      width: 90%;
      height: auto;
      margin: auto;
    }

    .close-button {
      font-size: 1rem;
    }
  }
}

// MOBILE
@media screen and (max-device-width: 601px) and (orientation:portrait) {
  .project-detail-page {
    width: 95%;
    margin: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30%;
}
}