nav {
  margin: auto;
  width: 100%;
  z-index: 150000;
  max-width: 100%;
}

.contact-modal{
  background-color: rgba(0, 0, 0, 0.421)!important;
  width: 100%;
  height: 100%;

  .contact-modal-content{
    position: relative;
    width: 80%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    gap: 5%;
    background: #232526;  /* fallback for old browsers */
background: linear-gradient(to bottom, #414345b7, #232526ad); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
padding: 2%;
backdrop-filter: blur(6px);


h2{
  color: #e0e0e0;
}

    .logoImageNavModale{
      width: 10rem;
    }

    a{
      border-radius: 15px;
      background-color: white;
      width: 50%;
      padding: 1%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1rem;
      gap: 2%;
      text-decoration: none;
      color: #000000;
    }

    button{
      position: absolute;
      top: 5%;
      right: 3%;
      color: white;
      font-size: 2rem;
    }
  }
}

.navbar {
  position: fixed;
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  padding: 0rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 40px;
  right: 0;
  left: 0;
  margin: auto;
  margin-top: 2%;
  height: 45px;

  button {
    background-color: transparent;
    border: none;
    font-size: 1rem;
  }

  .logoImageNavigation {
    width: 100px;

    img {
      width: 50px;
    }
  }

  .nav-links,
  .nav-cta {
    display: flex;
    list-style-type: none;
    gap: 3%;
  }

  .nav-link {
    color: #000000;
    text-decoration: none;
    padding: 0.5rem 0;
    position: relative;

    button {
      color: #000000;
      text-decoration: none;
    }

    &:after {
      content: '';
      display: block;
      height: 2px;
      width: 0;
      background: transparent;
      transition: width 0.5s ease, background-color 0.5s ease;
    }

    &:hover:after {
      width: 100%;
      background: #000000;
      border-radius: 15px;
    }
  }

  .nav-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    color: #FFFFFF;
    border-radius: 20px;
    text-decoration: none;
    font-weight: 800;
    font-family: 'Montserrat';
    transition: background-color 0.3s ease;
    height: 15px;
    padding: 5px 15px 5px 15px;

    button {
      color: white;
    }

    &:hover {
      background-color: darken(#000000, 10%);
    }
  }

  .mobile-menu-icon {
    display: none; // Caché par défaut
    cursor: pointer;
    color: #000000;
  }

  .nav-modal,
  .contact-modal {
    display: none; // Caché par défaut
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Ajoute un fond sombre semi-transparent
    z-index: 150001; // S'assurer qu'il est au-dessus d'autres éléments
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #FFFFFF;
      padding: 20px;
      border-radius: 25px;
      position: relative;
      height: 50%;
      width: 80%;

      .logoImageNavMobile {
        width: 240px;
        margin-bottom: 0;
      }

      a,
      button {
        font-family: 'Montserrat';
      }

      .nav-link-button {
        background-color: rgb(0, 0, 0);
        color: #FFFFFF;
        text-decoration: none;
        text-align: center;
        border-radius: 15px;
        padding: 3%;
      }

      .nav-cta {
        a {
          display: none;
        }

        display: none;
      }

      h2 {
        margin-bottom: 20px;
      }

      .contact-option {
        background-color: #f0f0f0;
        border: none;
        padding: 10px 20px;
        margin: 10px 0;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #e0e0e0;
        }
      }
    }

    .close-modal {
      position: absolute;
      top: 10px;
      right: 15px;
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  .services-link,
  .portfolio-link {
    position: relative;

    button {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .services-submenu,
    .portfolio-submenu {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #FFFFFF;
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
      border-radius: 0 0 10px 10px;
      z-index: 150001;

      .nav-link {
        display: block;
        padding: 10px 20px;
        width: 200px;
        white-space: nowrap;

        &:hover {
          background-color: #f0f0f0;
        }
      }

      .services-submenu-level2,
      .portfolio-submenu-level2 {
        display: none;
        position: absolute;
        top: 0;
        left: 100%;
        background-color: #FFFFFF;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        border-radius: 0 10px 10px 10px;
        z-index: 150002;

        .nav-link {
          padding: 10px 20px;
          width: 200px;

          &:hover {
            background-color: #f0f0f0;
          }
        }
      }

      .nav-link:hover + .services-submenu-level2,
      .nav-link:hover + .portfolio-submenu-level2 {
        display: block;
      }
    }

    &:hover .services-submenu,
    &:hover .portfolio-submenu {
      display: block;
    }
  }
}

// Grand écrans
@media screen and (min-width: 1500px) {
  .navbar {
    width: 50%;
    height: 55px;
  }
}

// Tablette
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .navbar {
    width: 80%;
    height: 55px;
    margin-top: 5%;

    .nav-link-button {
      display: none;
    }
  }

  .contact-modal{
    background-color: rgba(0, 0, 0, 0.821)!important;
    width: 100%;
    height: 100%;
  
    .contact-modal-content{
      position: relative;
      width: 80%;
      height: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
      gap: 5%;
      background: #232526;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 2%;
  


  h2{
    color: #e0e0e0;
    font-size: 3rem;
  }
  
      .logoImageNavContact{
        width: 10rem;
      }
  
      a{
        border-radius: 15px;
        background-color: white;
        width: 50%;
        padding: 1%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1rem;
        gap: 2%;
        text-decoration: none;
        color: #000000;
      }
  
      button{
        position: absolute;
        top: 5%;
        right: 3%;
        color: white;
        font-size: 2rem;
      }
    }
  }
}

// Mobile
@media screen and (max-width: 600px) {
  .navbar {
    width: 80%;
    margin: auto;
    margin-top: 30px;

    .nav-links,
    .nav-cta {
      display: none; // Cache les liens de navigation en version mobile
    }

    .mobile-menu-icon {
      display: flex; // Affiche le bouton menu en version mobile
    }

    .nav-modal,
    .contact-modal {
      display: flex; // Prépare l'affichage de la modale pour le menu mobile

      .modal-content {
        backdrop-filter: blur(8px);
        background-color: rgba(255, 255, 255, 0.616);

        .logoImageNavMobile {
          width: 180px;
          height: auto;
        }

        .nav-link-button {
          width: 80%;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
            rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        }

        .close-modal {
          display: none;
        }
      }
    }
  }
}
