.LayoutSpecific{
max-width: 90%;
margin: auto;
display: flex;

.SpecificPage{
    margin: auto;
    width: 100%;
    display: flex;
    padding-top: 10%;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    height: 70vh;
    padding-bottom: 10%;
    
    .PhotosSpecificMobile{
        display: none;
    }

    .BlocSpecific1{
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;

        .blocTextIntroSpecific{
        }
    

        .service-icons-desktop{
            display: flex;
            align-items: center;
            justify-content: space-around;
            height: 10%;
            font-size: 2rem;
            margin-bottom: 2%;
            width: 100%;
            gap: 5%;
            margin: 0;
        }

        h4{
            margin-bottom: 0;
        }

        h2{
            margin-top: 0;
            margin-bottom: 0;
           text-transform:uppercase;
           line-height: 2.5rem;
           font-size: 2.5rem;
        }

        .logoPaitaServices{
            width:15rem;
        }

        p{
            margin-top: 1%;
            margin-bottom: 5%;
            text-align: justify;
            font-size: 0.85rem;
            line-height: 1.5rem;
            
        }
        .service-icons{
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            font-size: 1.5rem;
            gap: 2%;
            display: none;
        }

        .HeaderSpecific{





        }




        .ContentSpecific{
            display: flex;
            justify-content:flex-end;
            align-items: center;



            .LogoSpecific{
                display: flex;
                flex-direction: row;
                width: 30%;
                gap: 1rem;

                img{
                    width: 50px;
                    height: auto;
                }
            }

            .ButtonSpecific {
                width:70%;

                button {
                    color: white;

                    width: 100%;
                    border: none;
                    height: 40px;
                    border-radius: 20px;
                    background: #0F2027;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

                }
            }

        }

        .ListeCles{
            margin: 0;
            padding: 0;
            width: 100%;

            ul{

            }
            li{
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #232526;  /* fallback for old browsers */
                background: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
                background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                text-decoration: none;
                list-style: none;
                margin-bottom: 2%;
                height: 35px;
                font-weight: 700;
                color: white;
                border-radius: 20px;
                font-size: 0.75rem;
                letter-spacing: 0.1rem;
                text-transform: uppercase;

            }
        }


    }


    .BlocSpecific2{
        height: 100%;
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;



        .PhotosSpecific{
            height: 100%;
            width: 100%;
            
    
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 20px;

            }


            
        }


    }





}

}

@import './SpecificServicePageMobile.scss';
@import './SpecificServicePageTablette.scss';
















