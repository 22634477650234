

@media screen and (max-width: 600px)  {


        .service-card {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
        
            .btnInfosProjet{
                color:black;
                font-size: 1rem;
            }
        
            .service-header{
                display: flex;
                flex-direction: column;
                align-content: flex-start;
                background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                width: 90%;
                margin: auto;
                border-radius: 20px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                color: white;
                height: 330px;
        
        .colonneImage{
            display: none;
            width: 100%;
            height: 60px;
            order: 2;
        
            img{
                height: 100%;
                border-radius:0px 0px 20px 20px;
                width: 100%;
                object-fit: cover;
            }
        }
        
        .colonneContenu{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 20px;
            width: 80%;
            margin: auto;
            order: 1;
        
                .ligne1{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    h4{
                        color: rgb(255, 255, 255);
                        text-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
                        order: 2;
        
                    }
        
                    .tag{
                        background-color: rebeccapurple;
                        padding: 5px;
                        border-radius: 10px;
                        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
                        filter: drop-shadow(0px 20px 120px currentColor);
                        order: 1;
                        margin-bottom: 2%;
        
        
                    }
        
                }
        
                .ligne2{
                    text-align: center;
                    
                }
        
                .ligne3{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    button{
                        width: 100%;
                        margin-top: 2%;
                        margin-bottom: 6%;
                        border: none;
                        border-radius: 8px;
                        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
                    }
        
                    .icons {
                        font-size: 1.5rem;
                        display: flex;
                        gap: 10px;
                      }
                
                }
                
        
            }
            }
        
        }
        
    
}