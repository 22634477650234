// Conteneur de la section services
section.servicesSection {
  background: url("../../../../public/img/services.webp"); /* Chemin relatif depuis le dossier public */
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 100%;
  margin: auto;
  height: auto;
  background-position-x: -20%;
  background-position-y: -20%;
  padding-bottom: 5%;
  padding-top: 5%;
 
  .desktop{
    display: block;
  }

  .mobile{
    display: none;
  }
  



  // Bouton pour afficher les services en détails
  .button-details {
    width: 300px;
    margin: auto;
    margin-top: 3%;
  }


  // Header de la section services


  // Conteneur des Cards de services
  .flexContainer{
    width: 80%;
    margin: auto;
    margin-top: 0;
    .serviceBlock{
      margin: auto;
    }
  }

}

@import './ServicesMobile.scss';
@import './ServicesTablette.scss';