.service-card {
    padding: 1%;
    display: flex;
    justify-content: center;

    .service-header {
        display: flex;
        flex-direction: row;
        align-content: space-around;
        background: linear-gradient(to right, #414345, #232526);
        width: 100%;
        margin: auto;
        border-radius: 20px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        color: white;
        height: 200px;
        cursor: pointer;

        .colonneImage {
            flex: 1;
            img {
                height: 100%;
                border-radius: 20px 0 0 20px;
                width: 100%;
                object-fit: cover;
            }
        }

        .colonneContenu {
            flex: 2;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 20px;

            .ligne1 {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                width: 100%;
                h4 {
                    color: rgb(255, 255, 255);
                    text-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
                }

                .tag {
                    padding: 5px;
                    border-radius: 10px;
                    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
                    filter: drop-shadow(0px 20px 120px currentColor);
                    
                    &.Web {
                        background-color: #6a1b9a; /* Violet */
                    }

                    &.Design {
                        background-color: #388e3c; /* Vert */
                    }

                    &.SEO {
                        background-color: #ff8f00; /* Orange */
                    }

                    &.Maintenance {
                        background-color: #1976d2; /* Bleu */
                    }

                    &.Applications {
                        background-color: #c2185b; /* Rose */
                    }

                    &.Consultation {
                        background-color: #fbc02d; /* Jaune */
                    }
                }
            }

            .ligne2 {
                text-align: justify;
            }

            .ligne3 {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                button {
                    border: none;
                    border-radius: 8px;
                    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
                }

                .icons {
                    display: flex;
                    gap: 10px;
                }
            }
        }
    }
}

@import './DetailedServicesComponentMobile.scss';
@import './DetailedServicesComponentTablette.scss';