
.detailed-parcours{
    max-width: 75%;
    margin: auto;
    padding-top: 10%;

    .headerParcoursDetailed{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
    }
    
    h2{
        line-height: 6rem;
        text-shadow: none;
        font-size: 6rem;
    }

    .logo-parcours{
        width:400px;
        height: 100%;
    }


}


@import './B-DetailedParcoursResponsive.scss';