.project-card {
  cursor: pointer;
  background: #232526;  /* fallback for old browsers */
  background: linear-gradient(to right, rgba(65, 67, 69, 0.78), rgba(35, 37, 38, 0.85)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 200px;
  width: 100%;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.117) 0px 13px 27px -5px, rgba(0, 0, 0, 0.138) 0px 8px 16px -8px;
}

.card-content-project {
  width: 90%;
  height: 100%;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);

  p {
    font-size: 0.85rem;
    text-align: center;
    line-height: 0.85rem;
    text-align-last: center;
    width: 90%;
    margin-top: 0;
  }

  button {
    border-radius: 10px;
    margin-top: 2%;
    border: none;
    padding: 3%;
  }
}

.project-card-image {
  height: 20%;
  width: auto;
  margin-bottom: 2%;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.29));
}

// TABLETTE
@media screen and (min-device-width: 600px) and (orientation: portrait) {
}

// MOBILE
@media screen and (max-device-width: 600px) and (orientation: portrait) {
  .project-card {
    cursor: pointer;
    width: 100%;
    height: 200px;
    width: 100%;
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.117) 0px 13px 27px -5px, rgba(0, 0, 0, 0.138) 0px 8px 16px -8px;
  }

  .card-content-project {
    width: 90%;
    height: 100%;
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);

    h4 {
      font-size: 0.9rem;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      margin-top: 2%;
    }

    p {
      font-size: 0.65rem;
      text-align: justify;
      text-align-last: center;
    }

    button {
      border: none;
      border-radius: 10px;
      margin-top: 5%;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
  }

  .project-card-image {
    height: 30%;
    width: auto;
  }
}