// TABLETTE
@media screen and (min-width: 601px) and (max-width: 1024px) {
    
    .philo-section{
        background: url("../../..//Assets/parcours.webp") no-repeat right / contain;
        margin: auto;
        width: 100%;


    
        .philo-grid {
            width: 90%;
            
        }
    }
    // Carte philosophie
    .philo-card {
        cursor: pointer;
        transition: transform 0.4s ease-out;
        transform-style: preserve-3d;
        

        &:hover {
            transform: scale(1.15);
            z-index: 100000;

        }

        &.flipped {
            .card-front { transform: rotateY(180deg); }
            .card-back { transform: rotateY(0deg); }
            z-index: 100000;
        }

        .card-front, .card-back {
            position: absolute;
            top: 0;
            left: 0;
            width: 90%;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 15px;
            backface-visibility: hidden;
            transition: transform 0.6s linear;
            border-radius: 20px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            color: white;
            background: linear-gradient(to right, #434343d3, #000000dc);
            backdrop-filter: blur(10px);
        }

        .card-front {
            h5 {
                font-size: 2rem;
                color: white;
                
            }
        }

        .card-back {
            background-color: #11285c;
            transform: rotateY(-180deg);

            h6, p {
                margin: 0;
                color: white !important;
                font-size: 0.8rem!important;
            }

            p { margin-top: 0.1px!important; 
            font-size: 0.75rem;}

            .card-icon {
                width: 20px!important;
                height: 20px;
            }
        }
    }


}