// Tailles de police de base
$font-size-base: 1rem; // 16px
$font-size-lg: 1.25rem; // 20px
$font-size-sm: 0.875rem; // 14px
$font-size-xs: 0.75rem; // 12px

// Tailles de police pour les titres
$font-size-h1: 2.5rem; // 40px
$font-size-h2: 5rem; // 32px
$font-size-h3: 1.75rem; // 28px
$font-size-h4: 1.5rem; // 24px
$font-size-h5: 1.25rem; // 20px
$font-size-h6: 1rem; // 16px

// Tailles de police pour mobile
$font-size-h1-mobile: 1.5rem; // 24px
$font-size-h2-mobile: 1.375rem; // 22px
$font-size-h3-mobile: 1.25rem; // 20px
$font-size-h4-mobile: 1.125rem; // 18px
$font-size-h5-mobile: 1rem; // 16px
$font-size-h6-mobile: 0.875rem; // 14px

// Tailles de police pour tablette
$font-size-h1-tablet: 2rem; // 32px
$font-size-h2-tablet: 1.75rem; // 28px
$font-size-h3-tablet: 1.5rem; // 24px
$font-size-h4-tablet: 1.375rem; // 22px
$font-size-h5-tablet: 1.25rem; // 20px
$font-size-h6-tablet: 1.125rem; // 18px

// Taille de police pour les paragraphes
$font-size-paragraph: 0.9rem; // 16px
$font-size-paragraph-tablet: 1.125rem; // 18px
$font-size-paragraph-mobile: 0.875rem; // 14px

// Taille de police pour les tags
$font-size-tag: 0.90rem; // 12px

// Points de rupture pour les media queries
$breakpoint-xs: 480px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
