

@media screen and (max-width: 600px){
    .detailed-services{
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        align-items: flex-start;
        padding-top: 25%;
        height: auto;
        width: 90%;
        margin: auto;
        

        .tag-select {
            text-align: center;
            width: 100%;
            padding: 10px;
            margin-bottom: 20px;
            font-size: 16px;
            border: 1px solid #ccc;
            border-radius:10px;
            
          }
    
        .headerDetailedServices{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 90%;
            margin: auto;
            text-align: center;
    
    
    
        img{
            width: 200px;
            order: 1;
        }
    
        h2{
            font-size: 2rem;
            line-height: 2rem;
            order: 2;
        }
    }
    
    
        .service-tags{
            display: flex;
            width: 90%;
            margin: auto;
            gap: 10px;
            margin-top: 2%;
            .tag-button{
                border-radius: 15px;
                border: none;
                font-size: 1rem;
                padding: 0.5%;
                &:hover {
                    background-color: #dcdcdc;
                    color: #000;
                  }
            
                  &:active {
                    background-color: #ccc;
                    color: #000;
                  }
            
                  &.active {
                    background-color: #333;
                    color: #fff;
                  }
    
            }
        }
    
        .service-section{
            height: auto;
            margin-top: 2%;
        }
    }
}