// SECTION
.homePage {
  position: relative;
  overflow: hidden;
  height: 100vh;

  // Bloc icone RS
  .socialHome {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 8%;
    right: 0;
    gap: 1%;

    // ICONES RS
    img {
      width: 2.5rem;
      filter: drop-shadow(0px 20px 120px currentColor);
    }
    :hover {
      scale: 1.1;
    }
  }

  // Illustration de fond - mains
  .fond-mains {
    overflow: hidden; 

    .mains {
      object-fit: cover; 
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%; 
      height: 100%; 
    }
  }

  // BLOC TITRE ANIME h1
  .texteHome {
    margin: auto;
    position: absolute;
    right: 0;
    left: 0;
    top: 60%;
  }

  // Texte LYON - GAP
  .textLoca {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 40%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #232526;
    background: -webkit-linear-gradient(to right, #414345ba, #232526bd);
    background: linear-gradient(to right, #414345ba, #232526bd);
    height: 3rem;
    border-radius: 30rem 30rem 0 0;
    text-transform: uppercase;
    backdrop-filter: blur(12px);
    a {
      text-decoration: none;
      color: white;
    }
    h3 {
      margin-top: 0;
      color: rgb(255, 254, 254);
      font-weight: 300;
      text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
      font-size: 1.25rem;
    }
  }
}

// LOGO PAÏTAWEB CENTRAL
.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .logoCentral {
    display: flex;
    width: 65%;
    margin: auto;
  }
}

// Importer les fichiers spécifiques aux médias
@import 'HomePageTablette';
@import 'HomePageMobile';