.desktop{
    display: none;
  }

  // MOBILE
@media screen and (max-width: 600px) {


  
    section.servicesSection{
        padding-top: 50px;
        padding-bottom: 50px;


        background: url("../../../../public/img/services.webp"); /* Chemin relatif depuis le dossier public */
        background-size: 80%;
        background-repeat: no-repeat;
        max-width: 100%;
        margin: auto;
        height: auto;
        background-position-x: -20%;
        background-position-y: 10%;
        padding-bottom: 5%;
        padding-top: 5%;
      
 
        
        
        .desktop{
            display: none;
        }

        
        
        .mobile{
            display: block;
        }

        .flexContainer{
          width: 100%;
          margin: auto;
          margin-top: 10%;
          margin-bottom: 10%;
          .serviceBlock{
            margin: auto;
          }
        }

        
    }

    
    

    
}
