.parcoursSection {
    background: url("../../../Assets/mainparcours.webp");
    background-position: top left;
    background-size: 35%;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 5rem;






    .parcoursHeader {
        width: 70%;
        margin: auto;
        .ampoule {
            width: 8%;
            margin-bottom: 0;
            margin-top: 0;
        }

        h2{
            margin-bottom: 0;
            width: 50%;
            margin: auto;
            line-height: 4.75rem;
        }
        h3{
            margin-top: 0;
            color: rgb(96, 96, 96);
            margin-bottom: 2%;
        }

        p{
            margin: auto;
        }

    
    
    
        .details-button{
            display: flex;
            justify-content: center;
            margin-top: 2%;
            margin-bottom: 2%;
        }



}

.ParcoursBlock
  {
    height: auto;
  }



}

@import './A-ParcoursResponsive.scss';

    
