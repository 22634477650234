@media screen and (min-width: 601px) and (max-width: 1024px)  {

    .service-card {
        padding: 1%;
    
    
        .service-header{
            display: flex;
            flex-direction: row;
            align-content: flex-start;
            background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            width: 90%;
            margin: auto;
            border-radius: 20px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
            color: white;
            height: 200px;
    
    .colonneImage{
        width: 20%;
    
        img{
            height: 100%;
            border-radius: 20px 0 0 20px;
            width: 100%;
            object-fit: cover;
        }
    }
    
    .colonneContenu{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 20px;
        width: 80%;
    
            .ligne1{
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                width: 100%;
                h4{
                    color: rgb(255, 255, 255);
                    text-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    
                }
    
                .tag{
                    background-color: rebeccapurple;
                    padding: 5px;
                    border-radius: 10px;
                    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
                    filter: drop-shadow(0px 20px 120px currentColor);
    
    
                }
    
            }
    
            .ligne2{
                text-align: justify;
                
            }
    
            .ligne3{
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                button{
                    border: none;
                    border-radius: 8px;
                    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
                }
    
                .icons {
                    display: flex;
                    gap: 10px;
                  }
            
            }
            
    
        }
        }
    
    }
    
}