// ServicesCarrousel.scss
@import "/src/GlobalStyles/variables.scss"; // Assurez-vous que le chemin d'accès est correct.
@import '../../ServicesCard/Style/ServicesCard.scss';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
.services-carousel {
    position: relative;
    box-shadow: none;
    background-color: transparent;
    max-width: 100%!important;
    margin: 0 auto;
    margin-bottom: 10%;
    margin-top: 5%;
  
    .blocCardServices {
      box-shadow: none;
      background-color: transparent;
      max-width: 100%;
      margin: 0 auto;
      display: flex; // Assure que le contenu est centré
      justify-content: center; // Centre le contenu
    }
  
    .slick-slide {
      box-shadow: none;
      background-color: transparent;
      display: flex;
      justify-content: center;
    }
  
    .slick-dots li button:before {
      color: #000;
    }
  
    .slick-prev, .slick-next {
      display: none !important;
    }
    
    .slick-list {
      overflow: hidden;
    }
    
    .slick-track {
      display: flex;
      justify-content: center;

    }
  }