.all-projects {
  width: 90%;
  margin: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 3%;
  padding-bottom: 3%;
  min-height: 50vh!important;
  margin-bottom: 5%!important;

  .header-all-projects {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 5%;

    h2 {
      font-size: 4rem;
      line-height: 3.8rem;
      text-transform: uppercase;
    }

    img {
      width: 275px;
      height: 100%;
    }
  }

  .menu-all-projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1%;
    margin-top: 2%;

    .desktop-menu {
      width: 100%;
      gap: 2%;
      display: flex; // Afficher le menu desktop par défaut
      button {
        padding: 0.5% 1%;
      }
    }

    .mobile-menu {
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;
      display: none; // Masquer le menu mobile par défaut

      select {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        font-size: 16px;
        width: 100%;
      }
    }

    button {
      border: none;
      border-radius: 20px;
      font-size: 1rem;
      padding: 0.5%;

      &:hover {
        background-color: #dcdcdc;
        color: #000;
      }

      &:active {
        background-color: #ccc;
        color: #000;
      }

      &.active {
        background-color: #333;
        color: #fff;
      }
    }
  }

  .projects-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: auto;
    margin-top: 2%;
    width: 100%;
    column-gap: 70px;
    row-gap: 30px;
    margin-bottom: 5%;
    margin-top: 5%;
  }
}

// Media queries pour afficher le bon menu en fonction de la taille de l'écran
@media (min-width: 1024px) {
  .menu-all-projects {
    .desktop-menu {
      display: flex; // Afficher le menu desktop
    }

    .mobile-menu {
      display: none; // Masquer le menu mobile
    }
  }
}

@import './B-ProjectsPageMobile.scss';
@import './B-ProjectsPageTablette.scss';