// ServiceCard.scss
@import "/src/GlobalStyles/variables.scss"; // Assurez-vous que le chemin d'accès est correct.
.blocCardServices{
.service-card {
  display: flex;
  border-radius: 18px;
  overflow: hidden;
  text-align: left;
  width: 98%;
  height: 400px;
  color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
h4{
  font-size: 2rem;
  color: #ffffff;

}

strong {
  font-weight: 700;
}

h5{
  font-weight: 500;
  color: #ffffff;

}

p{
  font-family: 'Manrope';
  line-height: 1.35rem;
  font-size: 0.9rem;
  margin-top: 2%;
  margin-bottom: 1%;
  padding: 1%;
  border-radius: 20px;
  background-color: #ffffff26;
  min-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}
  
  .service-image {
    width: 25%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
    }
  }

  .service-content {
    width: 75%;
    padding: 2%;
    padding-top: 5%;
    padding-bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;


    .services-tags {
      width: 100%;
      gap: 10px;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);


      li {
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.256);
        font-family: 'Montserrat';
        font-weight: 700;
        gap: 5px;
        list-style: none;
        padding: 0;
        margin: 0;
        border-radius: 10px;
        padding-left: 5%;
        padding: 1.5%;
        font-size: 0.75rem;
        color: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;

      }
    }

    .service-icons {
      justify-content: flex-end;
      display: flex;
      gap: 1rem;
      font-size: 2.5rem;
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.39));
      margin-bottom: 0;

    }
  }
}}

@import './ServicesCardMobile.scss';
@import './ServicesCardTablette.scss';
