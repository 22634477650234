// Section Philosophie
.philo-section {
    background: url("../../..//Assets/parcours.webp") no-repeat right / contain;
    background-size: 35%;
    background-position-y: -50px;
    margin: auto;
    width: 100%;
    margin-top: 5%;
    padding-bottom: 5%;

    user-select: none; /* Désactive la sélection du texte */
    -webkit-user-select: none; /* Désactive la sélection du texte pour les navigateurs WebKit */
    -moz-user-select: none; /* Désactive la sélection du texte pour Firefox */
    -ms-user-select: none; /* Désactive la sélection du texte pour Internet Explorer/Edge */
    -o-user-select: none; /* Désactive la sélection du texte pour les anciens navigateurs Opera */



    // Grille de cartes
    .philo-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        height: 325px;
        width: 70%;
        margin: auto;
    }

    // Carte philosophie
    .philo-card {
        cursor: pointer;
        transition: transform 0.4s ease-out;
        transform-style: preserve-3d;
        

        &:hover {
            transform: scale(1.15);
            z-index: 100000;

        }

        &.flipped {
            .card-front { transform: rotateY(180deg); }
            .card-back { transform: rotateY(0deg); }
            z-index: 100000;
        }

        .card-front, .card-back {
            position: absolute;
            top: 0;
            left: 0;
            width: 90%;
            height: 130px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 15px;
            backface-visibility: hidden;
            transition: transform 0.6s linear;
            border-radius: 20px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            color: white;
            background: linear-gradient(to right, #434343d3, #000000dc);
            backdrop-filter: blur(10px);
        }

        .card-front {
            h5 {
                font-size: 2rem;
                color: white;
            }
        }

        .card-back {
            background-color: #11285c;
            transform: rotateY(-180deg);

            h6, p {
                margin: 0;
                color: white !important;
            
            }

            p { margin-top: 0.5rem; 
                font-size: 1rem;
            }

            h6{
                font-size: 1.25rem;
            }

            .card-icon {
                width: 30px;
                height: 30px;
            }
        }
    }

    // Titre du verso de la carte
    .card-back-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    // Illustration de la carte
    .card-icon {
        width: 70px;
        height: 70px;
    }
}

@import './PhiloMobile.scss';
@import './PhiloTablette.scss';

