// Composant carrousel Parcours
.parcoursCarrousel {
    position: relative;
    width: 80%;
    margin: auto;
    height: 205px; // Ajustez en fonction du contenu
    margin-bottom: 0;

    user-select: none; /* Désactive la sélection du texte */
    -webkit-user-select: none; /* Désactive la sélection du texte pour les navigateurs WebKit */
    -moz-user-select: none; /* Désfactive la sélection du texte pour Firefox */
    -ms-user-select: none; /* Désactive la sélection du texte pour Internet Explorer/Edge */
    -o-user-select: none; /* Désactive la sélection du texte pour les anciens navigateurs Opera */
    margin-bottom: 5%;
    .colonne1 {
        display: flex;
    }


    // Card Stap du carrousel
    .carouselCard {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        height: 100%;
        background: linear-gradient(to right, #434343, #161616c0);
        backdrop-filter: blur(6px);
        color: #fff;
        padding: 20px;
        border-radius: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: transform 0.5s ease, width 0.5s ease;
        z-index: 1;

        .contenuCard {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

        }

        .tagLigne {}

        // Numéro + Titre + Texte (Colonne 1)
        .colonne1 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 75%;
            margin-bottom: 1%;

            .ligneUn {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 10px;

                .stepNumber {
                    font-size: 2rem;
                    color: rgb(255, 255, 255);
                }

                .stepTitle {
                    font-size: 1.75rem;
                    color: rgb(255, 255, 255);
                }
            }


            .stepDescription {
                font-size: 0.9rem;
                line-height: 1.1rem;
                width: 100%;
                text-align: justify;
                text-align-last: left;
                font-family: 'Manrope';

            }


        }

        // Etapes clés liste
        .stepKeyPoints {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            margin-top: 0;
            gap: 10px;

            // Etape clé
            .keyStep {
                background-color: rgb(255, 255, 255);
                color: #000;
                width: 100%;
                margin-bottom: 10px;
                border-radius: 10px;
                font-family: 'Manrope';
                font-weight: 400;
                font-size: 0.85rem;
                text-align: left;
                line-height: 0.95rem;
                padding: 1%;
                min-height: 30px;
            }
        }

        // Illustration de l'étape
        .stepLogo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15%;

            img {
                width: 100%;
                height: 80px;
            }
        }

        &.active {
            z-index: 5;
        }

        @for $i from 0 through 4 {
            &:nth-child(#{$i + 1}) {
                z-index: 5 - $i;
                transform: translateX(-50%) scale(1 - $i * 0.05) translateY(-$i * 5%);
            }
        }

        .stepNumber {
            font-weight: 100;
        }

    }


}

@import './ParcoursCarrouselMobile.scss';
@import './ParcoursCarrouselTablette.scss';