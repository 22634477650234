@import 'variables';

body {
  font-size: $font-size-base;
}

h1 {
  font-size: $font-size-h1;
  margin: 0;
  padding: 0;
  @media (max-width: $breakpoint-xs) {
    font-size: $font-size-h1-mobile;
    }
}

h2 {
  margin: 0;
  padding: 0;
  font-size: $font-size-h2;
  font-family: 'Manrope', sans-serif;
  @media (max-width: $breakpoint-xs) {
    font-size: $font-size-h2-mobile;
  }
}

h3 {
  margin: 0;
  padding: 0;
  font-size: $font-size-h3;
  @media (max-width: $breakpoint-xs) {
    font-size: $font-size-h3-mobile;
  }
}

h4 {
  margin: 0;
  padding: 0;
  font-size: $font-size-h4;
  @media (max-width: $breakpoint-xs) {
    font-size: $font-size-h4-mobile;
  }
}

h5 {
  margin: 0;
  padding: 0;
  font-size: $font-size-h5;
  @media (max-width: $breakpoint-xs) {
    font-size: $font-size-h5-mobile;
  }
}

h6 {
  margin: 0;
  padding: 0;
  font-size: $font-size-h6;
  @media (max-width: $breakpoint-xs) {
    font-size: $font-size-h6-mobile;
  }
}

p {
  margin: 0;
  padding: 0;
  font-size: $font-size-paragraph;
  font-weight: 400;
  @media (max-width: $breakpoint-xs) {
    font-size: $font-size-h6-mobile;
  }
}
// Ajoutez ici d'autres styles de base si nécessaire
