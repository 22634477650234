// ServicesCarrousel.scss
@import "/src/GlobalStyles/variables.scss"; // Assurez-vous que le chemin d'accès est correct.
@import '../../ServicesCard/Style/ServicesCard.scss';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
div.services-carousel {
    position: relative;
    box-shadow: none;
    background-color: transparent;
    max-width: 95%;
    margin: 0 auto;
    margin-bottom: 2%;
    margin-top: 0;
    .blocCardServices {
      box-shadow: none;
      background-color: transparent;
      max-width: 100%;
      margin: 0 auto;
      margin-top: 0;
      display: flex; // Assure que le contenu est centré
      justify-content: center; // Centre le contenu
    }
  
    .slick-slide {
      box-shadow: none;
      background-color: transparent;
      display: flex;
      justify-content: center;
      margin-bottom: 25px!important;
    }
  
    .slick-dots li button:before {
      color: #000;
    }
  
    .slick-prev, .slick-next {
      display: none !important;
    }
    
    .slick-list {
      overflow: hidden;

    }
    
    .slick-track {
      display: flex;
      justify-content: center;

    }

    .slick-slide {
      margin: 0 15px;
  }
  }

@import './ServicesCarrouselMobile.scss';
@import './ServicesCarrouselTablette.scss';