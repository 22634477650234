// MOBILE
@media screen and (max-width: 600px) {
    .homePage {
      background: url("../../../Assets/mainstablette.webp");
       background-position: center;
       background-size: 100%;
       background-position-y:7rem ;
       background-repeat: no-repeat;
       display: flex;
       max-height: 100vh;

       .fond-mains{
        display: none;
      }
   
        //BLOC RS
       .socialHome{
         position: absolute;
         bottom: 9%;
         gap: 4%;
         img{
           width: 35px;
         }
   
     
       }
   
    //    Bloc Texte animé
       .texteHome {
         top: 57%;
         width: 85%;
       }
   
    //    Texte LYON GAP
       .textLoca {
         position: absolute;
         bottom: 0;
         right: 0;
         justify-content: center;
         flex-direction: column;
background: linear-gradient(to right, #4b4b4bbb, #6d6d6daa); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

         height: 7%;
         width: 90%;
         backdrop-filter: blur(6px);

         h3 {
           color: rgb(255, 255, 255);
           text-align: center;
           order: 2;
           width: 100%;
           font-size: 0.85rem;
         }
   
         img {
           width: 25px;
           display: none;
         }
       }
       .logo {
         width: 75%;
   
         .logoCentral {
           width: 100%;
         }
       }
     }
   }