@media screen and (min-width: 601px) and (max-width: 1024px) {
    .detailed-services{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        width: 100%;
        margin: auto;
        padding-top: 15%;
    
        .headerDetailedServices{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 90%;
            margin: auto;
    
    
    
        img{
            width: 13rem;
            order: 2;
        }
    
        h2{
            font-size: 2.5rem;
            line-height: 2.5rem;
        }
    }
    
    
        .service-tags{
            display: flex;
            width: 90%;
            margin: auto;
            gap: 10px;
            margin-top: 2%;
            .tag-button{
                border-radius: 15px;
                border: none;
                font-size: 1rem;
                padding: 1%;
                &:hover {
                    background-color: #dcdcdc;
                    color: #000;
                  }
            
                  &:active {
                    background-color: #ccc;
                    color: #000;
                  }
            
                  &.active {
                    background-color: #333;
                    color: #fff;
                  }
    
            }
        }
    
        .service-section{
            height: auto;
            margin-top: 2%;
        }
    }
    
}