.detailed-services{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: flex-start;
    padding-top: 9%;
    height: auto;
    width: 90%;
    margin: auto;

    .headerDetailedServices{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: auto;



    img{
        width: 300px;
        order: 2;
    }

    h2{
        font-size: 4rem;
        line-height: 4rem;
    }
}


    .service-tags{
        display: flex;
        width: 90%;
        margin: auto;
        gap: 10px;
        margin-top: 2%;
        .tag-button{
            border-radius: 15px;
            border: none;
            font-size: 1rem;
            padding: 0.5%;
            &:hover {
                background-color: #dcdcdc;
                color: #000;
              }
        
              &:active {
                background-color: #ccc;
                color: #000;
              }
        
              &.active {
                background-color: #333;
                color: #fff;
              }

        }
    }

    .service-section{
        height: auto;
        margin-top: 2%;
    }
}

@import './DetailedServicesMobile.scss';
@import './DetailedServicesTablette.scss';