.App {
  // Remove display flex
}

.sidebar {
  position: fixed;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  z-index: 1000;
}

.nav-dots {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dot {
  width: 12px;
  height: 12px;
  margin: 8px 0;
  background-color: #bbb;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #000;
}

.landing-page {
}
.separator {
  height: 2px;
  background-color: #ccc;
  margin: 20px 0;
}

section {
}