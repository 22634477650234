@media screen and (max-width: 600px)  {
      .all-projects {
        padding-top: 20%;
    
        .header-all-projects {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
    
          h2 {
            font-size: 2.5rem;
            line-height: 2rem;
            text-transform: uppercase;
            order: 2;
            text-align: center;
            margin-bottom: 2%;
          }
    
          img {
            width: 175px;
            height: 100%;
            order: 1;
            margin-bottom: 3%;
            margin-top: 5%;
          }
        }
    
        .menu-all-projects {
          .desktop-menu {
            display: none; // Masquer le menu desktop
          }
    
          .mobile-menu {
            display: flex; // Afficher le menu mobile
          }
        }  .projects-container {
          display: flex;
          flex-direction: column;
          height: auto;
          margin-top: 2%;
          width: 100%;
          column-gap: 70px;
          row-gap: 30px;
          margin-bottom: 5%;
          margin-top: 5%;
        }
      }
    }