// TABLETTE
@media screen and (min-width: 601px) and (max-width: 1024px) {
    .contactSection{
        background: url("../../../Assets/shaking.webp"); /* Chemin relatif depuis le dossier public */
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin: auto;
        margin-bottom: 5%;
        margin-top: 5%;
        padding-bottom: 5%;
        padding-top: 5%;
        .ContactHeader{
            width: 70%;
            margin: auto;
    
         h2{
            margin-top: -3%;
            margin-bottom: 0;
         }
    
         h3{
            margin-top: 0;
            margin-bottom: 0;
         }
    
            .contactIcon{
                width: 15%;
                margin-bottom: 1%;
            }
        }
    }
    
    .boutonsContact{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        
        margin: auto;
        .button-contact{
            width: 45%;
            font-family: 'Montserrat';
            font-weight: 700;
            font-size: 2rem;
            background-color: rgb(255, 255, 255);
            padding: 1% 5% 1% 5%;
            font-family: 'Montserrat', sans-serif;
            border: none;
            border-radius: 15px;
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        }
    }
    
    
    .contactBlock {
        margin: auto;
        width: 85%;
    }
    

    
}