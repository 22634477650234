.text-typed-container {
    color: #000000;
    font-family: 'Montserrat';
    display: flex;
    justify-content: center!important;
    align-items: center;
    margin: auto;
    text-align: left;
    height: 2rem;
    font-size: 1.5rem;
    margin-top: 1%;
    width: 100%;
    


    .lightText{
      font-weight: 400;
    }

    h1{
    }
  }
  
  .text-typed {
    white-space: nowrap;
    margin-left: 5px;
    border-right: 3px solid #fff;
  }
  
  /* Styles spécifiques pour le curseur de Typed.js */
  .typed-cursor {
    opacity: 1;
    animation: blink 0.7s infinite;
  }
  
  /* Animation du curseur */
  @keyframes blink {
    50% { opacity: 0; }
  }


// Grand écrans
@media screen and (min-width: 1500px) and (max-width: 2580px) {
  .text-typed-container{
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    

  }



  .text-typed {
    white-space: nowrap;
    margin-left: 5px;
    border-right: 3px solid #fff;
  }

}


  // TABLETTE
  @media screen and (min-width: 601px) and (max-width: 1024px){
    .text-typed-container{
      color: #ffffff;
      font-family: 'Montserrat';
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 1.35rem;
      height: 1.5rem;
      width: 100%;
    }


    .lightText{
      padding-left: 2%;
    }

    .text-typed {
      white-space: nowrap;
      margin-left: 5px;
      border-right: 3px solid #fff;
    }


  }
  
  @media screen and (min-device-width: 201px) and (max-device-width: 624px) and (orientation: portrait){
  .text-typed-container{
    font-size: 1rem;
    width: 85%;
  }

  .fond-mains{
    display: none;
  }
  }

  @media screen and (max-width: 600px){
    .text-typed-container{
      color: #ffffff;
      font-family: 'Montserrat';
      display: flex;
      justify-content: center;
      font-size: 0.75rem;
      align-items: center;
      text-align: center;
      border-radius: 10px;
      background: #232526;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

.text-typed {
  white-space: nowrap;
  margin-left: 5px;
  border-right: 3px solid #fff;
}

.lightText{
  padding-left: 5%;
}
    }



    h1{
    }
  }

  