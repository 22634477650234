// MOBILE
@media screen and (max-width: 600px) {

    // ServiceCard.scss
  @import "/src/GlobalStyles/variables.scss"; // Assurez-vous que le chemin d'accès est correct.
  .blocCardServices{
  .service-card {
    display: flex;
    flex-direction: column;
    border-radius: 18px;
    overflow: hidden;
    text-align: left;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 650px;
    margin: auto;
    color: #ffffff;
  
  h4{
    font-size: 1.5rem;
    text-align: center;
  }
  
  strong {
    font-weight: 700;
  }
  
  h5{
    font-weight: 500;
    text-align: center;

  }
  
  p{
    font-family: 'Manrope';
    line-height: 1.25rem;
    font-size: 0.85rem;
    margin-top: 2%;
    margin-bottom: 1%;
    border-radius: 20px;
    background-color: #ffffff26;
    width: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 2%;
  }
    
    .service-image {
      width: 100%;
      height: 120px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 18px;
        border-bottom-left-radius: 0;
        border-top-right-radius: 18px;
  
      }
    }
  
    .service-content {
      width: 90%;
      padding-top: 5%;
      padding-bottom: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
  
  
      .services-tags {
        width: 100%;
        gap: 10px;
        padding: 0;
        display: flex;
        flex-direction: column;
        grid-template-columns: repeat(1, 1fr);
        text-align: center;
        align-items: center;
        justify-content: center;
        
  
  
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(255, 255, 255, 0.256);
          font-family: 'Montserrat';
          font-weight: 700;
          gap: 5px;
          list-style: none;
          padding: 0;
          margin: 0;
          border-radius: 10px;
          padding-left: 0%;
          padding: 1.5%;
          width: 100%;
          font-size: 0.75rem;
          color: rgb(255, 255, 255);
          filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.19));
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  
  
        }
      }
  
      .service-icons {
        justify-content: center;
        display: flex;
        font-size: 1.5rem;
        filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.19));
  
      }
    }
  }}
  
  }